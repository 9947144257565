import TeamsLogin from "./login";
import TeamsLoginFailed from "./failed";

const currentPath = document.body.getAttribute("data-current-path");
if (currentPath && currentPath === "teams/pages") {
    const action = document.body.getAttribute("data-current-action")
    if (action === "login") {
        new TeamsLogin();
    } else if (action === "login_failed") {
        new TeamsLoginFailed()
    }
}
